<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleMobile' : 'mainTitle twoLines'">{{ $t('mainTitle') }}</h1>
    </v-row>
    <v-row justify="center" v-bind:class="mobile ? 'firstSectionMobile' : 'firstSection'" no-gutters>
      <v-col v-bind:cols="mobile ? 10 : 6">
        <div v-bind:class="mobile ? '' : 'textFirstSection'" v-html="$t('text1')"></div>
        <br v-if="mobile">
      </v-col>
      <v-col v-bind:cols="mobile ? 10 : 6">
        <div v-bind:class="mobile ? '' : 'textFirstSection'" v-html="$t('text2')"></div>
      </v-col>
    </v-row>
    <v-row justify="center" v-bind:class="mobile ? 'secondSectionMobile' : 'secondSection'" no-gutters>
      <v-col cols="5" class="textLeft" v-if="!mobile">
        <h3 v-html="$t('subtitle1')"></h3>
        <div v-html="$t('text3')" class="inTextLeft"></div>
        <v-img
            class="imgLeft"
            :src="require('@/assets/picto/picto8.svg')"
            contain
            height="100"
        />
        <h3 v-html="$t('subtitle3')"></h3>
        <div v-html="$t('text5')" class="inTextLeft"></div>
      </v-col>
      <v-col v-bind:cols="mobile ? 1 : 2">
        <v-img
            :src="require('@/assets/picto/separator9.png')"
            contain
            v-bind:height="mobile ? 850 : 600"
        />
      </v-col>
      <v-col v-bind:cols="mobile ? 9 : 5">
        <v-img
            v-bind:class="mobile ? 'imgMobile' : 'imgRight'"
            :src="require('@/assets/picto/picto7.svg')"
            contain
            v-bind:height="mobile ? 75 : 100"
        />
        <h3 v-html="$t('subtitle1')" v-if="mobile"></h3>
        <div v-html="$t('text3')" v-bind:class="mobile ? 'textSecondSectionMobile' : 'inTextLeft'"  v-if="mobile"></div>
        <v-img
            v-if="mobile"
            v-bind:class="mobile ? 'imgMobile' : 'imgRight'"
            :src="require('@/assets/picto/picto8.svg')"
            contain
            v-bind:height="mobile ? 75 : 100"
        />
        <h3 v-html="$t('subtitle2')"></h3>
        <div v-html="$t('text4')" v-bind:class="mobile ? 'textSecondSectionMobile' : 'inTextLeft'" ></div>
        <v-img
            v-bind:class="mobile ? 'imgMobile' : 'imgRight'"
            :src="require('@/assets/picto/picto2.svg')"
            contain
            v-bind:height="mobile ? 75 : 100"
        />
        <h3 v-html="$t('subtitle3')" v-if="mobile"></h3>
        <div v-html="$t('text5')" v-bind:class="mobile ? 'textSecondSectionMobile' : 'inTextLeft'" v-if="mobile"></div>
      </v-col>
    </v-row>
    <v-row justify="center" v-bind:class="mobile ? 'thirdSectionMobile' : 'thirdSection'" no-gutters>
      <h2 v-bind:class="mobile ? 'secondTitleMobile' : 'secondTitle'" v-html="$t('secondTitle')"></h2>
      <v-col cols="6" v-if="!mobile">
        <v-img
            :src="require('@/assets/vitaeImg/section9.png')"
            contain
            height="175"
        />
        <h3 class="titleSection" v-html="$t('subtitle4')"></h3>
        <div class="textSection" v-html="$t('text6')"></div>
      </v-col>
      <v-col cols="6" v-if="!mobile">
        <v-img
            :src="require('@/assets/vitaeImg/section33.png')"
            contain
            height="175"
        />
        <h3 class="titleSection" v-html="$t('subtitle5')"></h3>
        <div class="textSection" v-html="$t('text7')"></div>
      </v-col>
      <v-col cols="12" v-if="!mobile">
        <v-img
            style="margin-top: 40px"
            :src="require('@/assets/vitaeImg/section24.png')"
            contain
            height="175"
        />
        <h3 class="titleSection" v-html="$t('subtitle6')"></h3>
        <div class="textSectionBot" v-html="$t('text8')"></div>
      </v-col>
        <v-expansion-panels v-if="mobile" flat class="sectionMobile">
          <v-expansion-panel>
            <v-expansion-panel-header expand-icon="">
              <h3 class="titleSectionMobile"> {{ $t('subtitle4')}}</h3>
              <template v-slot:actions>
                <v-icon color="#57939E" x-large>
                  $expand
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p class="textSectionMobile" v-html="$t('text6')"></p>
              <br>
              <v-img
                  class="imgSections"
                  :src="require('@/assets/vitaeImg/section9.png')"
                  contain
                  height="150"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header expand-icon="">
              <h3 class="titleSectionMobile"> {{ $t('subtitle5')}}</h3>
              <template v-slot:actions>
                <v-icon color="#57939E" x-large>
                  $expand
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p class="textSectionMobile" v-html="$t('text7')"></p>
              <br>
              <v-img
                  class="imgSections"
                  :src="require('@/assets/vitaeImg/section4.png')"
                  contain
                  height="150"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header expand-icon="">
              <h3 class="titleSectionMobile"> {{ $t('subtitle6')}}</h3>
              <template v-slot:actions>
                <v-icon color="#57939E" x-large>
                  $expand
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p class="textSectionMobile" v-html="$t('text8')"></p>
              <br>
              <v-img
                  class="imgSections"
                  :src="require('@/assets/vitaeImg/section24.png')"
                  contain
                  height="150"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'EnterpriseSportComp',
  props: ['mobile'],
  data: () => ({}),
}
</script>

<i18n>
{
  "en": {
    "mainTitle": "Corporate sport",
    "text1": "<strong> More and more companies are committed to preserving the health of their employees. </strong> <br> Indeed, management policies that include the practice of a physical or sporting activity to serve objectives health and well-being have many advantages for society, for employees and for companies themselves.",
    "text2": "In addition to the individual physical, social and emotional benefits inherent in regular physical activity, a recent study by Goodwill-Management shows the impact of APS from an economic point of view both for the company, the employee and the civil society.",
    "text3": "<br> <div style = 'color: # 808080;'> - <strong style = 'color: # 57939E;'> 5% to 7% </strong> savings on annual health care costs <br> - Delay of <strong style = 'color: # 57939E;'> 6 years </strong> from the age of onset of dependency <br> - <strong style = 'color: # 57939E;'> 30th to 34 € / year < / strong> savings in health care spending <br> - <strong style = 'color: # 57939E;'> + 6% to 9% </strong> improvement in productivity <br> - <strong style = 'color: # 57939E;'> + 3 years </strong> of life expectancy </div>",
    "text4": "<br> <div style = 'color: # 808080;'> - + 1% to 14% improvement in net profitability <br> - + 6% to 9% improvement in productivity per employee </ div >",
    "text5": "<br> <div style = 'color: # 808080;'> - € 308 to € 348 savings in health spending <br> - Between 7% and 9% savings in healthcare costs </div>",
    "text6": "<br> <div style = 'color: # 808080;'> The association offers <strong> awareness days </strong> to sport and well-being for companies and works councils. <a class='linkText' href='/awareness-day'> SEE MORE </a> </div>",
    "text7": "<br> <div style = 'color: # 808080;'> We offer many adapted physical activities that adapts to all fitness levels and all desires: Active fit, yoga, Pilate, maintenance gym, Cross-Fit, Nordic walking ... <a class='linkText' href='/physical-activities-enterprise'> SEE MORE </a> </div>",
    "text8": "<br> <div style = 'color: # 808080;'> This intervention makes it possible to become aware of the risks of MSDs at work as well as the benefits of physical activity in general, but it becomes all the more interesting as it takes an interest in the employee's daily life and their own risks while providing them with suitable tools to protect themselves. <a class='linkText' href='/conference-diagnosis'> SEE MORE </a> </div>",
    "subtitle1": "For the employee",
    "subtitle2": "For the company",
    "subtitle3": "For the society",
    "subtitle4": "Sport health well-being awareness day",
    "subtitle5": "Adapted physical activities offered",
    "subtitle6": "Conferences and diagnostics",
    "secondTitle": "This study shows that an employer, through the development of physical activity within his company, will be able, in addition to improving his productivity and reducing overall health expenses, to be a real player in health prevention for his employees. collaborators!"

  },
  "fr": {
    "mainTitle": "Sport entreprise",
    "text1": "<strong>De plus en plus d’entreprises s’engagent pour préserver la santé de leurs collaborateurs.</strong> <br> En effet, les politiques de management qui incluent la pratique d’une activité physique ou sportive pour servir des objectifs de santé et de bien-être présentent de nombreux atouts pour la société, pour les salariés et les entreprises elles mêmes.",
    "text2": "En plus des bienfaits individuels sur les plans physique, social et émotionnel inhérent à une pratique régulière d’activité physique, une étude récente réalisée par Goodwill-Management montre l’impact de l’APS d’un point de vue économique à la fois pour l’entreprise, le salarié et la société civile.",
    "text3": "<br><div style='color: #808080;'>- <strong style='color: #57939E;'> 5% à 7% </strong> d'économie sur ses dépenses de santé annuelle <br> - Retardement de <strong style='color: #57939E;'> 6ans </strong> de l'âge de début de dépendance<br> - <strong style='color: #57939E;'> 30€ à 34€ /an </strong>d'économie sur son budget dépenses santé<br> - <strong style='color: #57939E;'> +6% à 9% </strong> d'amélioration de la productivité <br>- <strong style='color: #57939E;'> +3ans </strong>d'espérance de vie </div>",
    "text4": "<br><div style='color: #808080;'>- <strong style='color: #57939E;'>+1% à 14% </strong>  d'amélioration de la rentabilité nette  <br>-<strong style='color: #57939E;'> +6% à 9% </strong> d'amélioration de la productivité par salarié</div>",
    "text5": "<br><div style='color: #808080;'>- <strong style='color: #57939E;'>308€ à 348€ </strong>  d'économie des dépenses de santé <br>- Entre <strong style='color: #57939E;'> 7% et 9% </strong> d'économie de dépenses de santé</div>",
    "text6": "<br><div style='color: #808080;'>L'association propose des <strong>journées de sensibilisation </strong>au sport et au bien-être à destination des entreprises et comités d'entreprise. <a class='linkText' href='/awareness-day'>VOIR PLUS</a></div>",
    "text7": "<br><div style='color: #808080;'>Nous proposons de nombreuses activités physique adaptée qui s’adapte à tous les niveaux de forme et à toutes les envies : Fit active, yoga, Pilate, gym d’entretien, Cross-Fit, marche nordique... <a class='linkText' href='/physical-activities-enterprise'>VOIR PLUS</a></div>",
    "text8": "<br><div style='color: #808080;'>Cette intervention permet de prendre conscience des risques des TMS au travail ainsi que des bienfaits de l’activité physique en général, mais elle devient d’autant plus intéressante qu’elle s’intéresse au quotidien du salarié et à ses propres risques tout en lui donnant des outils adaptés pour se prémunir.<a class='linkText' href='/conference-diagnosis'>VOIR PLUS</a></div>",
    "subtitle1": "Pour le salarié",
    "subtitle2": "Pour l'entreprise",
    "subtitle3": "Pour la société",
    "subtitle4": "Journée de sensibilisation sport santé bien-être",
    "subtitle5": "Les activités physiques adaptées proposées",
    "subtitle6": "Conférences et diagnostics",
    "secondTitle": "Cette étude montre qu'un employeur, au travers du développement de l'activité physique au sein de son entreprise, pourra, en plus d'améliorer sa productivité et de réduire les dépenses de santé globale, être un réel acteur de prévention santé pour ses collaborateurs !"
  }
}
</i18n>

<style scoped>

@media screen and (min-width: 1500px) {
  .imgRight {
    margin: 80px 450px 120px 0;
  }

  .imgLeft {
    margin: 120px 0 100px 450px;
  }
  .titleSection {
    padding: 40px 180px 20px;
    font-family: Comfortaa, sans-serif;
    text-align: center;
    color: #57939E;
  }
  .textSectionBot {
    padding-left: 400px;
    padding-right: 400px;
  }
  .textFirstSection {
    font-size: 17px;
    padding-right: 100px;
    padding-left: 20px;
  }
}
@media screen and (max-width: 1500px) {
  .imgRight {
    margin: 80px 200px 120px 0;
  }
  .imgLeft {
    margin: 75px 0 75px 200px;
  }
  .titleSection {
    padding: 40px 120px 20px;
    font-family: Comfortaa, sans-serif;
    text-align: center;
    color: #57939E;
  }
  .textSectionBot {
    padding-left: 250px;
    padding-right: 250px;
  }
  .textFirstSection {
    font-size: 16px;
    padding-right: 100px;
    padding-left: 20px;
  }
}

.sectionMobile {
  padding-bottom: 50px;
}
.textSectionMobile {
  font-size: 14px;
  text-align: left;
  color: #808080;
}
.titleSectionMobile {
  font-size: 16px;
  margin-right: 100px;
  font-family: Comfortaa, sans-serif;
  color: #57939E;
}

.textSecondSectionMobile {
  margin-bottom: 60px;
}

.imgMobile {
  margin-right: 200px;
  margin-bottom: 30px;
}

.firstSection {
  color: #808080;
  padding-right: 200px;
  padding-left: 200px;
  padding-bottom: 80px;
}

.firstSectionMobile {
  font-size: 14px;
  color: #808080;
  padding-bottom: 40px;
}

.secondSection {
  padding: 80px 200px 0;
  color: #57939E;
  background-color: #D5EEF4;
}

.secondSectionMobile {
  font-size: 14px;
  padding-top: 40px;
  color: #57939E;
  background-color: #D5EEF4;
}

.textLeft {
  text-align: right;
}

.secondTitle {
  padding-bottom: 50px;
  text-align: center;
  color: #1579A7;
  font-family: Comfortaa, sans-serif;
}

.secondTitleMobile {
  padding-bottom: 50px;
  padding-left: 30px;
  padding-right: 30px;
  text-align: left;
  font-size: 16px;
  color: #1579A7;
  font-family: Comfortaa, sans-serif;
}

.thirdSection {
  padding: 80px 200px;
}

.thirdSectionMobile {
  padding-top: 40px;
}

.textSection {
  padding-left: 50px;
  padding-right: 50px;
}

.textSection >>> .linkText {
  color: #57939E;
  text-decoration: underline;
}

.textSectionMobile >>> .linkText {
  color: #57939E;
  text-decoration: underline;
}

.textSectionBot >>> .linkText {
  color: #57939E;
  text-decoration: underline;
}

</style>
