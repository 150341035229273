<template>
  <div>
    <EnterpriseSportComp v-bind:mobile="mobile"/>
  </div>
</template>

<script>
import EnterpriseSportComp from '@/components/desktop/Enterprise/EnterpriseSportComp'

  export default {
    name: 'EnterpriseSport',
    props: ['mobile'],
    components: {
      EnterpriseSportComp
    },
  }
</script>
